import React from "react"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

function ProductMarkdownContent({ value }) {
  return value
    .split("\n")
    .filter((text) => Boolean(text.trim()))
    .map((text, idx) => (
      <ReactMarkdown
        key={text}
        source={text}
        className={idx === 0 ? "" : "mt-3"}
      />
    ))
}

export function ProductPrimaryDisplay(props) {
  return (
    <div className="shadow-lg md:shadow-xl mx-auto my-10 md:max-w-4xl">
      <article
        className="
           flex flex-col md:flex-row flex-shrink-0
           md:p-5
           border-b border-solid border-gray-200
           "
      >
        <div className="md:max-w-sm md:w-1/2 md:border md:mr-3">
          <Img fluid={props.imgFluid} alt={props.imgAlt} />
        </div>
        <section className="md:w-1/2 p-3">
          <h2 className="text-4xl md:text-5xl mt-5 font-light leading-tight tracking-wider">
            {props.title}
          </h2>
          <p className="text-sm md:text-base mb-5 italic">
            <span>({props.quantity})</span>
          </p>
          <p>
            <ProductMarkdownContent value={props.description} />
          </p>
        </section>
      </article>
    </div>
  )
}

import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { ProductPrimaryDisplay } from "../components/productPage/ProductPrimaryDisplay"
import SEO from "../components/seo"

const ProductTemplate = ({ data: { sanityProduct: product } }) => (
  <Layout>
    <SEO title={product.title} />
    <ProductPrimaryDisplay
      title={product.name}
      quantity={product.quantity}
      imgFluid={product.poster.asset.fluid}
      imgAlt={product.name}
      description={product.mainDescription}
    />
  </Layout>
)

export default ProductTemplate

export const query = graphql`
  query ProductTemplate($id: String!) {
    sanityProduct(id: { eq: $id }) {
      id
      quantity
      name
      mainDescription
      poster {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`
